import { RegistrationPage } from "./_registration_page";
import { ProductPage } from "./_product_page";
import { HomePage } from "./_home_page";
import { ShoppingCartPage } from "./_shopping_cart_page";
import { CheckoutLoginPage } from "./_checkout_login_page";
import { CheckoutCredentialsPage } from "./_checkout_credentials_page";
import { CheckoutPaymentPage } from "./_checkout_payment_page";
import { LoginPage } from "./_login_page";
import { PasswordSetPage } from "./_password_set_page";
import { MyAccountPage } from "./_my_account_page";
import { LicensePlatesPage } from "./_license_plates_page";
import { FAQPage } from "./_faq_page";
import { LocationsPage } from "./_locations_page";
import { LocationAreasPage } from "./_location_areas_page";
import { PaymentOverviewPage } from "./_payment_overview_page";
import { ConsumerReservationsPage } from "./_consumer_reservations_page";

export { Page } from "./_page";

export const pageClasses = [
	HomePage,
	RegistrationPage,
	ProductPage,
	ShoppingCartPage,
	CheckoutLoginPage,
	CheckoutCredentialsPage,
	CheckoutPaymentPage,
	LoginPage,
	PasswordSetPage,
	MyAccountPage,
	LicensePlatesPage,
	FAQPage,
	LocationsPage,
	LocationAreasPage,
	PaymentOverviewPage,
	ConsumerReservationsPage
];
